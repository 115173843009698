import ObscuredInput from '@/components/Reusable/Formik/ObscuredInput';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Link from 'next/link';
import React from 'react';

function LoginForm({
  handleInputChange,
  formData,
  loading,
  handleLoginSubmit,
}) {
  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={(e) => {
        e.preventDefault();
        handleLoginSubmit();
      }}
    >
      <TextField
        variant='outlined'
        onChange={handleInputChange}
        placeholder='Enter your email address'
        label='Email'
        name='email'
        type='email'
        style={{ marginBottom: '1rem' }}
        InputLabelProps={{
          style: { color: '#040059' },
        }}
      />
      <ObscuredInput
        variant='outlined'
        onChange={handleInputChange}
        placeholder='Enter your password'
        label='Password'
        name='password'
        type='password'
        style={{ marginBottom: '1rem' }}
        InputLabelProps={{
          style: { color: '#040059' },
        }}
      />
      <LoadingButton
        loading={loading}
        color='secondary'
        variant='contained'
        type='submit'
        disabled={!formData.email || !formData.password || loading}
      >
        Log In
      </LoadingButton>
      <Link
        href='/forgot-password'
        style={{
          marginTop: '.5rem',
          textDecoration: 'none',
          color: '#040059',
        }}
      >
        Forgot password?
      </Link>
    </form>
  );
}

export default LoginForm;
