import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

export default function PasswordRules(props) {
  const { formData, setIsValid, noMatch } = props;

  const passwordResults = ruleResults(
    formData.newPassword || '',
    formData.newRepeat || '',
    noMatch
  );

  useEffect(() => {
    setIsValid(passwordResults.isValid);
  }, [passwordResults.isValid, setIsValid]);

  return (
    <div>
      <ul>
        <Rule
          isValid={passwordResults.capital}
          text='Must contain a capital letter.'
        />
        <Rule
          isValid={passwordResults.lowercase}
          text='Must contain a lowercase letter.'
        />
        <Rule isValid={passwordResults.number} text='Must contain a number.' />
        <Rule
          isValid={passwordResults.specialChar}
          text='Must contain a special character.'
        />
        <Rule
          isValid={passwordResults.minLength}
          text='Must be at least 8 characters.'
        />
        {noMatch ? null : (
          <Rule isValid={passwordResults.match} text='Re-entry must match.' />
        )}
      </ul>
    </div>
  );
}

function Rule({ isValid, text }) {
  const color = isValid ? 'green' : 'red';
  return (
    <Typography
      component='li'
      variant='body2'
      sx={{
        color,
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <RuleIcon isValid={isValid} />
      {text}
    </Typography>
  );
}

function RuleIcon(props) {
  const { isValid } = props;

  if (isValid) {
    return <CheckIcon />;
  } else {
    return <CloseIcon />;
  }
}

function ruleResults(password, passwordAgain, noMatch) {
  const checkCapital = (password) => {
    const regex = /[A-Z]/g;
    return regex.test(password);
  };

  const checkMatch = (password, passwordAgain) => {
    return noMatch || password === passwordAgain;
  };

  const checkSpecialChar = (password) => {
    const regex = /[!@#$%^&*(),.?":{}|<>]/g;
    return regex.test(password);
  };

  const checkMinLength = (password) => {
    return password.length >= 8;
  };

  const checkNumber = (password) => {
    const regex = /[0-9]/g;
    return regex.test(password);
  };

  const checkLowercase = (password) => {
    const regex = /[a-z]/g;
    return regex.test(password);
  };

  const result = {
    capital: checkCapital(password),
    match: checkMatch(password, passwordAgain),
    specialChar: checkSpecialChar(password),
    minLength: checkMinLength(password),
    number: checkNumber(password),
    lowercase: checkLowercase(password),
  };

  result.isValid = Object.values(result).every((value) => value);

  return result;
}
