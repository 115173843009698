import CornerstoneLogo from '@/assets/logos/CornerstoneLogo';
import LoginForm from '@/components/PageSpecific/login/LoginForm';
import NewPasswordForm from '@/components/PageSpecific/login/NewPasswordForm';
import TrusteLogo from '@/components/PageSpecific/login/TrusteLogo/TrusteLogo';
import PrivacyPolicyDialog from '@/components/PageSpecific/privacy-policy/PrivacyPolicyDialog';
import TermsOfUseDialog from '@/components/PageSpecific/terms-of-use/TermsOfUseDialog';
import SnackBarAlert from '@/components/Reusable/GeneralUse/SnackBarAlert';
import PageTitle from '@/components/Reusable/Typography/PageTitle';
import classes from '@/styles/login.module.css';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import moment from 'moment-timezone';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

function Login() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [openTOU, setOpenTOU] = useState(false);
  const [openPP, setOpenPP] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alert, setAlert] = useState(undefined);
  const [snackPack, setSnackPack] = useState([]);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  // this is used for the first password change
  const [finalizingNewPassword, setFinalizingNewPassword] = useState(false);
  const [cognitoReponse, setCognitoReponse] = useState(null);

  const updateSnack = (alertObj) => {
    setSnackPack((prev) => [
      ...prev,
      { ...alertObj, key: new Date().getTime() },
    ]);
  };

  const handleLoginSubmit = async () => {
    setLoading(true);
    try {
      const trimEmail = formData.email.trim();
      const trimPassword = formData.password.trim();

      const user = await Auth.signIn(trimEmail, trimPassword);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoReponse(user);
        setFinalizingNewPassword(true);
        setLoading(false);
        return;
      }

      setFormData({
        email: '',
        password: '',
      });
      router.push(router.query?.callbackUrl || '/hub');
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        const trimEmail = formData.email.trim();
        await Auth.forgotPassword(trimEmail);
        router.push('/confirm-password-reset');
      } else {
        setLoading(false);
        updateSnack({
          severity: 'error',
          message: `Failed to login: ${error.message}`,
        });
      }
    }
  };

  const handleNewPasswordSubmit = async () => {
    try {
      setLoading(true);
      const trimPassword = formData.newPassword.trim();

      await Auth.completeNewPassword(cognitoReponse, trimPassword);

      router.push(router.query?.callbackUrl || '/hub');
    } catch (error) {
      setLoading(false);
      setFinalizingNewPassword(false);
      updateSnack({
        severity: 'error',
        message: `Failed to create new password: ${error.message}`,
      });
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      style={{
        backgroundImage: `url("${process.env.NEXT_PUBLIC_STATIC_ASSETS_URL}cis-hub-bg.jpg")`,
      }}
      className={classes.wrapper}
    >
      <PageTitle />
      <Box m='auto' width={'80%'} maxWidth={400}>
        <CornerstoneLogo white />

        <Box
          component={Paper}
          width={'100%'}
          bgcolor='white'
          justifyContent='center'
          display='flex'
          flexDirection='column'
          elevation={12}
          className={classes.paper}
        >
          {finalizingNewPassword ? (
            <NewPasswordForm
              formData={formData}
              handleInputChange={handleInputChange}
              loading={loading}
              handleNewPasswordSubmit={handleNewPasswordSubmit}
            />
          ) : (
            <LoginForm
              formData={formData}
              handleInputChange={handleInputChange}
              loading={loading}
              handleLoginSubmit={handleLoginSubmit}
            />
          )}
          <Divider sx={{ mt: 2, mb: 1 }} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button
              onClick={() => setOpenPP(true)}
              variant='text'
              style={{ backgroundColor: 'transparent' }}
            >
              <Typography color='black'>Privacy policy</Typography>
            </Button>
            <Button
              onClick={() => setOpenTOU(true)}
              variant='text'
              style={{ backgroundColor: 'transparent' }}
            >
              <Typography color='black'>Terms of use</Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <TrusteLogo />
          </Box>
          <Typography
            sx={{
              mt: 2,
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            © {moment().year()} Cornerstone Information Systems, Inc.
          </Typography>
        </Box>
      </Box>
      <TermsOfUseDialog open={openTOU} setOpen={setOpenTOU} />
      <PrivacyPolicyDialog open={openPP} setOpen={setOpenPP} />
      <SnackBarAlert
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
        alert={alert}
        setAlert={setAlert}
        snackPack={snackPack}
        setSnackPack={setSnackPack}
      />
    </Grid>
  );
}

export default Login;
